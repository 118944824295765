var __jsx = React.createElement;
import React from 'react';
import OfferCard from "../Offer/Card";
export default function NurseryCards(_ref) {
  var offer = _ref.offer,
    offerType = _ref.offerType,
    noShadow = _ref.noShadow;
  var handleMcoLink = function handleMcoLink(url) {
    window.open(url);
  };
  return __jsx(OfferCard, {
    merchantId: offer.merchantId,
    key: offer.offerIdMls,
    offerIdMls: offer.offerIdMls,
    offerType: offerType,
    offerImg: offer.offerImageURL,
    pointsEarn: offer.discount,
    discountType: offer.discountType,
    dollarSpend: offer.minTransactionAmountToRedeem,
    conversionRate: offer.pointsConversionRate,
    importantInfo: offer.longDescription,
    merchantLogo: offer.merchantLogoURL,
    offerEndDate: offer.eventEndDate,
    redemptionType: offer.redemptionType,
    onClick: null,
    shopNow: offer.merchantWebsiteURL ? function () {
      return handleMcoLink(offer.merchantWebsiteURL);
    } : '',
    findStore: offer.merchantStoreLocatorURL ? function () {
      return handleMcoLink(offer.merchantStoreLocatorURL);
    } : '',
    noShadow: noShadow
  });
}