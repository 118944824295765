export var CardSlickSettings = {
  infinite: false,
  centerMode: false,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  dots: false,
  arrows: false,
  draggable: true,
  responsive: [{
    breakpoint: 360,
    settings: {
      slidesToShow: 1.125,
      slidesToScroll: 1
    }
  }, {
    breakpoint: 580,
    settings: {
      slidesToShow: 1.25,
      slidesToScroll: 1
    }
  }]
};