import { colours, fontSizes, layoutSizes, customFonts } from "../../constants/css";
import styled from 'styled-components';
var mcoEnticement = "/static/home/enticement_MCO.jpg";
var pebble = "/static/home/bonus-point-pebble.png";
import InfoBox from "../InfoBox";
import { Button } from "../_global";
var LinkCard = styled.section.withConfig({
  displayName: "style__LinkCard",
  componentId: "sc-1se50n2-0"
})(["background-image:linear-gradient(to left,", ",", ");"], colours.qantasWhite, colours.qantasGray91);
var LinkCardBox = styled.div.withConfig({
  displayName: "style__LinkCardBox",
  componentId: "sc-1se50n2-1"
})(["background-color:", ";border-radius:5px;box-shadow:0 2px 3px 0 rgba(0,0,0,0.2),0 14px 13px 0 rgba(0,0,0,0.05);padding:0.5rem 2rem;position:relative;margin:5rem auto 0;max-width:400px;width:100%;@media only screen and (min-width:", "){margin:0 auto;padding:0.5rem 3.25rem;}"], colours.qantasWhite, layoutSizes.ipadPortraitWidth);
var LinkTitle = styled.p.withConfig({
  displayName: "style__LinkTitle",
  componentId: "sc-1se50n2-2"
})(["font-family:", ";font-size:2.1875rem;line-height:2rem;margin:0 0 1rem;letter-spacing:0;"], customFonts.ciutadellaMed);
var LinkSubTitle = styled.p.withConfig({
  displayName: "style__LinkSubTitle",
  componentId: "sc-1se50n2-3"
})(["font-size:", ";line-height:1.5rem;margin-top:1.25rem;color:#000;padding-top:1.5rem;@media only screen and (min-width:", "){padding-top:0;}"], fontSizes.large, layoutSizes.ipadPortraitWidth);
var LinkCopy = styled.div.withConfig({
  displayName: "style__LinkCopy",
  componentId: "sc-1se50n2-4"
})(["display:flex;flex-direction:column;justify-content:center;background:url(", ") no-repeat;background-size:cover;background-position:center;color:", ";"], mcoEnticement, colours.qantasWhite);
var LinkCardWrapper = styled.div.withConfig({
  displayName: "style__LinkCardWrapper",
  componentId: "sc-1se50n2-5"
})(["text-align:center;padding:0px 0px 3rem;", ""], function (props) {
  return props.isSingleOffer && "\n    flex-grow: 1;\n    min-width: 50%;\n    padding: 0.5rem 0 1.5rem 0;\n\n    @media only screen and (min-width: ".concat(layoutSizes.ipadPortraitWidth, ") {\n      font-size: ").concat(fontSizes.xl, ";\n\n      > div {\n        margin: 0 auto 0 12%;\n\n        h3 {\n\n        }\n      }\n    }\n  ");
});
var LinkButton = styled(Button).withConfig({
  displayName: "style__LinkButton",
  componentId: "sc-1se50n2-6"
})(["max-width:15rem;margin:0.625rem auto;"]);
var LinkCopyContainer = styled.div.withConfig({
  displayName: "style__LinkCopyContainer",
  componentId: "sc-1se50n2-7"
})(["text-align:center;min-width:270px;margin:auto;padding:2.8125rem 0;a{display:block;}"]);
var LinkCardContainer = styled(LinkCopyContainer).withConfig({
  displayName: "style__LinkCardContainer",
  componentId: "sc-1se50n2-8"
})(["position:relative;padding:0;margin-bottom:0;h3{font-weight:normal;font-family:", ";margin-bottom:0;font-size:inherit;}"], customFonts.ciutadellaMed);
var LinkCardSubText = styled.p.withConfig({
  displayName: "style__LinkCardSubText",
  componentId: "sc-1se50n2-9"
})(["margin:0 0 1.875rem 0;font-size:inherit;"]);
var MerchantsLogo = styled.div.withConfig({
  displayName: "style__MerchantsLogo",
  componentId: "sc-1se50n2-10"
})(["position:relative;margin:0 0 1rem;height:101px;@media ", "{height:154px;}@media only screen and (min-width:", "){height:136px;}"], function (_ref) {
  var theme = _ref.theme;
  return theme.responsiveQuery.desktop;
}, layoutSizes.ipadPortraitWidth);
var OffersTC = styled.p.withConfig({
  displayName: "style__OffersTC",
  componentId: "sc-1se50n2-11"
})(["font-size:0.625rem;margin:1.5rem auto 0;color:", ";"], colours.qantasGrey);
var HowItWorks = styled(InfoBox).withConfig({
  displayName: "style__HowItWorks",
  componentId: "sc-1se50n2-12"
})(["margin:0.625rem 0;div{color:", ";}"], colours.qantasDarkerGrey);
var LinkWrapper = styled.div.withConfig({
  displayName: "style__LinkWrapper",
  componentId: "sc-1se50n2-13"
})([".slick-list{min-height:", ";}", "{max-width:", ";width:100%;margin:auto;padding-left:1rem;padding-right:1rem;}", "{padding:0;font-size:", ";margin:auto;> h3,> p{padding:0 0.5rem;}}", "{min-width:", ";margin:0 auto;}", "{width:", ";}@media only screen and (min-width:", "){display:flex;flex-direction:", ";", "{margin:", ";}", "{padding:0;margin:", ";font-size:", ";}", "{margin:", ";}}"], function (props) {
  return props.isSingleOffer ? '0' : '430px';
}, LinkCopyContainer, function (props) {
  return props.isSingleOffer ? '400px' : 'none';
}, LinkCardContainer, fontSizes.xl, LinkCopy, function (props) {
  return props.isSingleOffer ? '50%' : '100%';
}, OffersTC, function (props) {
  return props.isSingleOffer ? '270px' : '100%';
}, layoutSizes.ipadPortraitWidth, function (props) {
  return props.isSingleOffer ? 'row' : 'column';
}, LinkCopyContainer, function (props) {
  return props.isSingleOffer ? '0 12% 0 auto' : 'auto';
}, LinkCardContainer, function (props) {
  return props.isSingleOffer ? '0 auto 0 12%' : 'auto';
}, function (props) {
  return props.isSingleOffer ? fontSizes.xl : '1.625rem';
}, OffersTC, function (props) {
  return props.isSingleOffer ? '1rem auto 0.5rem' : '1.5rem auto 0';
});
var Pebble = styled.div.withConfig({
  displayName: "style__Pebble",
  componentId: "sc-1se50n2-14"
})(["background:url(", ") no-repeat;background-size:contain;height:104px;width:104px;position:absolute;top:-4.5rem;left:37%;@media only screen and (min-width:", "){right:-3rem;top:-3rem;left:inherit;}"], pebble, layoutSizes.ipadPortraitWidth);
var HowItWorksLink = styled.div.withConfig({
  displayName: "style__HowItWorksLink",
  componentId: "sc-1se50n2-15"
})(["margin:0.625rem;> a{color:", ";cursor:pointer;text-decoration:underline;}"], colours.qantasCharcoal);
var DisclaimerCopy = styled.div.withConfig({
  displayName: "style__DisclaimerCopy",
  componentId: "sc-1se50n2-16"
})(["color:white;margin-top:26px;> a{color:white;display:inline;cursor:pointer;text-decoration:underline;}"]);
export { LinkCard, LinkCardBox, LinkWrapper, LinkTitle, LinkCardSubText, LinkSubTitle, LinkCopy, LinkButton, HowItWorks, LinkCardWrapper, MerchantsLogo, LinkCopyContainer, OffersTC, Pebble, HowItWorksLink, LinkCardContainer, DisclaimerCopy };