import styled from 'styled-components';
export var CarouselContainer = styled.div.withConfig({
  displayName: "style__CarouselContainer",
  componentId: "sc-1rfm6pf-0"
})(["position:relative;"]);
export var LinkCardContainer = styled.div.withConfig({
  displayName: "style__LinkCardContainer",
  componentId: "sc-1rfm6pf-1"
})(["position:relative;text-align:center;min-width:270px;margin:auto;"]);
export var LinkOfferCard = styled.div.withConfig({
  displayName: "style__LinkOfferCard",
  componentId: "sc-1rfm6pf-2"
})(["display:inline-block;max-width:270px;margin:0 15px 10px 0;"]);