import _toConsumableArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var __jsx = React.createElement;
import React from 'react';
import moment from 'moment-timezone';
import { LinkCardContainer, LinkCardWrapper, OffersTC, LinkCardSubText } from "./style";
import NurseryCardsCarousel from "../NurseryCardsCarousel";
var NurseryCards = function NurseryCards(_ref) {
  var offers = _ref.offers,
    cardTypeText = _ref.cardTypeText;
  var getCurrentTzDate = function getCurrentTzDate(date) {
    return moment.tz(date, 'Australia/Sydney').format('ha D MMMM YYYY');
  };
  var getEarliestOffer = function getEarliestOffer(offers) {
    var sortedOffers = _toConsumableArray(offers).sort(function (a, b) {
      return new Date(a.displayEndDate) - new Date(b.displayEndDate);
    });
    return sortedOffers[0];
  };
  var earliestOffer = getEarliestOffer(offers);
  var isSingleOffer = offers.length === 1;
  return __jsx(LinkCardWrapper, {
    isSingleOffer: isSingleOffer
  }, __jsx(LinkCardContainer, null, __jsx("h3", null, "Get started with ", offers.length === 1 ? 'this offer' : 'these offers', " when you link"), __jsx(LinkCardSubText, null, "your ", cardTypeText, " by ", getCurrentTzDate(earliestOffer.displayEndDate), "^:"), __jsx(NurseryCardsCarousel, {
    offers: offers
  }), __jsx(OffersTC, null, "^Offer available to new customers who link their ", cardTypeText, " between\xA0", getCurrentTzDate(earliestOffer.displayStartDate), " and\xA0", getCurrentTzDate(earliestOffer.displayEndDate), ".")));
};
NurseryCards.defaultProps = {
  offers: []
};
export default NurseryCards;