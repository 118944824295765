import _extends from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import { responsiveQuery } from "../../constants/css";
import { ArrowCarousel } from "../_global";
import { CardSlickSettings } from "../NurseryOffer/nurseryUtils";
import { CarouselContainer, LinkCardContainer, LinkOfferCard } from "./style";
import NurseryCards from "./NurseryCards";
var NurseryCardsCarousel = function NurseryCardsCarousel(_ref) {
  var offers = _ref.offers,
    offerType = _ref.offerType;
  var _useState = useState(0),
    activeSlide = _useState[0],
    setActiveSlide = _useState[1];
  var sliderRef;
  var isCarousel = offers.length > 2;
  CardSlickSettings.beforeChange = function (current, next) {
    setActiveSlide(next);
  };
  var onNext = function onNext() {
    sliderRef.slickNext();
  };
  var onPrevious = function onPrevious() {
    sliderRef.slickPrev();
  };
  var renderCards = function renderCards() {
    return offers.map(function (offer) {
      return __jsx(LinkOfferCard, {
        key: offer.offerIdMls
      }, __jsx(NurseryCards, {
        offer: offer,
        offerType: offerType || offer.offerType
      }));
    });
  };
  return __jsx(LinkCardContainer, null, isCarousel ? __jsx(React.Fragment, null, __jsx(CarouselContainer, null, __jsx(MediaQuery, {
    query: responsiveQuery.mobileAndTablet
  }, activeSlide !== 0 && __jsx(ArrowCarousel, {
    type: "prev",
    onClick: onPrevious
  }), activeSlide !== offers.length - 2 && __jsx(ArrowCarousel, {
    type: "next",
    onClick: onNext
  }), __jsx(Slider, _extends({}, CardSlickSettings, {
    ref: function ref(arg) {
      sliderRef = arg;
    }
  }), renderCards()))), __jsx(MediaQuery, {
    query: responsiveQuery.tabletAndDesktop
  }, renderCards())) : renderCards());
};
NurseryCardsCarousel.defaultProps = {
  offers: []
};
export default NurseryCardsCarousel;